//定义路由
export default [
    {
        name: 'metadata',
        path: '/metadata',
        meta: {requireAuth: true, roles: ['D']},
        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/mainPage.vue'),
        redirect: "/metadata/data",
        children: [
            {
                path: '/metadata/data',
                meta: {requireAuth: true, roles: ['D']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/originalData.vue'),
            },
            {
                path: '/metadata/dataparsing',
                meta: {requireAuth: true, roles: ['D']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/dataParsing.vue'),
            },
            {
                path: '/metadata/monitorDataParsing',
                meta: {requireAuth: true, roles: ['D']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/monitorDataParsing.vue'),
            },
            {
                path: '/metadata/datasend',
                meta: {requireAuth: true, roles: ['D']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/dataSend.vue'),
                redirect: "/metadata/datasend/networking",
                children: [
                    {
                        path: '/metadata/datasend/networking',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'networking'}),
                    },
                    {
                        path: '/metadata/datasend/readData',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'readData'}),
                    },
                    {
                        path: '/metadata/datasend/setFrequency',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'frequency'}),
                    },
                    {
                        path: '/metadata/datasend/setStatus',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'status'}),
                    },
                    {
                        path: '/metadata/datasend/information',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'information'}),
                    },
                    {
                        path: '/metadata/datasend/wifi',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'wifi'}),
                    },
                    {
                        path: '/metadata/datasend/upgrade',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/metadata/datasend/threshold',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'threshold'}),
                    },
                ]
            },
            {
                path: '/metadata/mesh',
                meta: {requireAuth: true, roles: ['D']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshDataSend.vue'),
                redirect: "/metadata/mesh/networking",
                children: [
                    {
                        path: '/metadata/mesh/networking',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'networking', sn: route.query.sn}),
                    },
                    {
                        path: '/metadata/mesh/specifyNetworking',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'specifyNetworking'}),
                    },
                    {
                        path: '/metadata/mesh/meshSetStatus',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'meshSetStatus'}),
                    },
                    {
                        path: '/metadata/mesh/upgrade',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/metadata/mesh/emptyPower',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'emptyPower'}),
                    },
                    {
                        path: '/metadata/mesh/plantPolyline',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'plantPolyline'}),
                    },
                    {
                        path: '/metadata/mesh/gatewayList',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/meshOperating.vue'),
                        props: (route) => ({op: 'gatewayList'}),
                    },
                ]
            },
            {
                path: '/metadata/old',
                meta: {requireAuth: true, roles: ['D']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/oldDataSend.vue'),
                redirect: "/metadata/old/networking",
                children: [
                    {
                        path: '/metadata/old/networking',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/oldOperating.vue'),
                        props: (route) => ({op: 'networking'}),
                    },
                    {
                        path: '/metadata/old/readData',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/oldOperating.vue'),
                        props: (route) => ({op: 'readData'}),
                    },
                    {
                        path: '/metadata/old/information',
                        meta: {requireAuth: true, roles: ['D']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/oldOperating.vue'),
                        props: (route) => ({op: 'information'}),
                    },
                ]
            },
            {
                path: '/metadata/power',
                meta: {requireAuth: true, roles: ['SA']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/main.vue'),
                redirect: '/metadata/power',
                children: [
                    {
                        path: "/metadata/power",
                        component: () => import(/* webpackChunkName: "admin-user" */ '@/views/power/menu.vue'),
                        meta: {requireAuth: true, roles: ['SA']},
                        redirect: '/metadata/power/module',
                        children: [
                            {
                                path: '/metadata/power/module',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/menu1.vue'),
                                props: (route) => ({flag: 'module'}),
                            },
                            {
                                path: '/metadata/power/plant',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/menu1.vue'),
                                props: (route) => ({flag: 'plant'}),
                            },
                            {
                                path: '/metadata/power/statistics',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/statistics.vue'),
                            }
                        ]
                    },
                    {
                        path: '/metadata/power/plantLocation',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/map/plantLocation.vue'),
                    },
                    {
                        path: '/metadata/power/plantNewLocation',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/map/plantNewLocation.vue'),
                    },
                    {
                        path: '/metadata/power/layout',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/layout/main.vue'),
                        redirect: '/metadata/power/layout/layout2D',
                        children: [
                            {
                                path: '/metadata/power/layout/layout2D',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/layout/layout2D.vue'),
                                props: (route) => ({flag: 'module'}),
                            },
                            {
                                path: '/metadata/power/layout/layout3D',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/layout/layout3D.vue'),
                                props: (route) => ({flag: 'module'}),
                            },
                            {
                                path: '/metadata/power/layout/layoutSet',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/layout/layoutSet.vue'),
                                props: (route) => ({flag: 'module'}),
                            },
                            {
                                path: '/metadata/power/layout/layoutStatic',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/layout/layoutStatic.vue'),
                                props: (route) => ({flag: 'module'}),
                            },
                            {
                                path: '/metadata/power/layout/layoutString',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "metadata" */ '@/views/power/layout/layoutString.vue'),
                                props: (route) => ({flag: 'module'}),
                            },
                        ]
                    },
                    {
                        path: '/metadata/plant',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/plant.vue'),
                        redirect: '/metadata/plant/plantList',
                        children: [
                            {
                                path: '/metadata/plant/plantList',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/plantList4admin.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                            {
                                path: '/metadata/plant/image',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/images.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber, flag: 'plant'}),
                            },
                            {
                                path: '/metadata/plant/moduleList',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/moduleListInPlant.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                            {
                                path: '/metadata/plant/types',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/types.vue'),
                                props: (route) => ({plantType: route.query.plantType, types: 'plant'}),
                            },
                            {
                                path: '/metadata/plant/distribution',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/types.vue'),
                                props: (route) => ({distributionMethod: route.query.distributionMethod, types: 'profit'}),
                            },
                        ]
                    },
                    {
                        path: '/metadata/plant/status',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/plant/plantStatus/plantStatus.vue'),
                        redirect: '/metadata/plant/status/blog',
                        children: [
                            {
                                path: '/metadata/plant/status/state',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/plantStatus/plantWorkingState.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                            {
                                path: '/metadata/plant/status/blog',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/plantStatus/plantBlog.vue'),
                                props: (route) => ({plantNumber: route.query.plantNumber}),
                            },
                        ]
                    },
                    {
                        path: '/metadata/plant/places',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/places/places.vue'),
                        redirect: '/metadata/plant/places/types',
                        children: [
                            {
                                path: '/metadata/plant/places/images',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/images.vue'),
                                props: (route) => ({placeName: route.query.placeName, flag: 'place'}),
                            },
                            {
                                path: '/metadata/plant/places/types',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/types.vue'),
                                props: (route) => ({placeType: route.query.placeType, types: 'place'}),
                            },
                            {
                                path: '/metadata/plant/places/lease',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-plant" */ '@/views/power/plant/types.vue'),
                                props: (route) => ({leaseMethod: route.query.leaseMethod, types: 'lease'}),
                            },
                        ]
                    },
                    {
                        path: '/metadata/module',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/module.vue'),
                        redirect: '/metadata/module/list',
                        children: [
                            {
                                path: '/metadata/module/list',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleList.vue'),
                                props: (route) => ({userName: route.query.userName}),
                            },
                            {
                                path: '/metadata/module/moduleSharing',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/sharingList.vue'),
                                props: (route) => ({userName: route.query.userName}),
                            },
                            {
                                path: '/metadata/module/monitorChanged',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/monitorsChangedList.vue'),
                                props: (route) => ({moduleId: route.query.moduleId}),
                            }
                        ]
                    },
                    {
                        path: '/metadata/moduleType',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleType/moduleType.vue'),
                        redirect: '/metadata/moduleType/list',
                        children: [
                            {
                                path: '/metadata/moduleType/list',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleType/moduleTypeList.vue'),
                                props: (route) => ({moduleType: route.query.moduleType}),
                            },
                            {
                                path: '/metadata/moduleType/specification',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleType/moduleTypeSpecification.vue'),
                                props: (route) => ({moduleType: route.query.moduleType}),
                            },
                            {
                                path: '/metadata/moduleType/images',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/plant/images.vue'),
                                props: (route) => ({moduleType: route.query.moduleType, flag: 'moduleType'}),
                            },
                        ]
                    },

                    {
                        path: '/metadata/module/trading',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/trading/trading.vue'),
                        redirect: '/metadata/module/trading/ownership',
                        children: [
                            {
                                path: '/metadata/module/trading/ownership',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/trading/moduleOwnership.vue')
                            },
                            {
                                path: '/metadata/module/trading/exchangeList',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import('@/views/power/module/trading/exchangeList.vue'),
                            },
                        ]
                    },
                    {
                        path: '/metadata/module/status',
                        meta: {requireAuth: true, roles: ['SA']},
                        component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleStatus/moduleStatus.vue'),
                        redirect: '/metadata/module/status/blog',
                        children: [
                            {
                                path: '/metadata/module/status/state',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleStatus/moduleWorkingState.vue'),
                                props: (route) => ({moduleId: route.query.moduleId}),
                            },
                            {
                                path: '/metadata/module/status/blog',
                                meta: {requireAuth: true, roles: ['SA']},
                                component: () => import(/* webpackChunkName: "admin-module" */ '@/views/power/module/moduleStatus/moduleBlog.vue'),
                                props: (route) => ({moduleId: route.query.moduleId}),
                            },
                        ]
                    },
                ]
            },
        ]
    }
]
