//定义路由
export default [
    {
        name: 'install',
        path: '/install',
        meta: {requireAuth: true, roles: ['I']},
        component: () => import(/* webpackChunkName: "install" */ '@/views/install/mainPage.vue'),
        redirect: "/install/data",
        children: [
            {
                path: '/install/data',
                meta: {requireAuth: true, roles: ['I']},
                component: () => import(/* webpackChunkName: "install" */ '@/views/install/originalData.vue'),
            },
            {
                path: '/install/dataparsing',
                meta: {requireAuth: true, roles: ['I']},
                component: () => import(/* webpackChunkName: "install" */ '@/views/install/dataParsing.vue'),
            },
            {
                path: '/install/datasend',
                meta: {requireAuth: true, roles: ['I']},
                component: () => import(/* webpackChunkName: "install" */ '@/views/install/dataSend.vue'),
                redirect: "/install/datasend/networking",
                children: [
                    {
                        path: '/install/datasend/networking',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/operating.vue'),
                        props: (route) => ({op: 'networking'}),
                    },
                    {
                        path: '/install/datasend/readData',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/operating.vue'),
                        props: (route) => ({op: 'readData'}),
                    },
                    {
                        path: '/install/datasend/wifi',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/operating.vue'),
                        props: (route) => ({op: 'wifi'}),
                    },
                    {
                        path: '/install/datasend/upgrade',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/operating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/install/datasend/threshold',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/operating.vue'),
                        props: (route) => ({op: 'threshold'}),
                    },
                ]
            },
            {
                path: '/install/mesh',
                meta: {requireAuth: true, roles: ['I']},
                component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshDataSend.vue'),
                redirect: "/install/mesh/networking",
                children: [
                    {
                        path: '/install/mesh/networking',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshOperating.vue'),
                        props: (route) => ({op: 'networking', sn: route.query.sn}),
                    },
                    {
                        path: '/install/mesh/specifyNetworking',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshOperating.vue'),
                        props: (route) => ({op: 'specifyNetworking'}),
                    },
                    {
                        path: '/install/mesh/meshSetStatus',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshOperating.vue'),
                        props: (route) => ({op: 'meshSetStatus'}),
                    },
                    {
                        path: '/install/mesh/upgrade',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshOperating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/install/mesh/plantPolyline',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshOperating.vue'),
                        props: (route) => ({op: 'plantPolyline'}),
                    },
                    {
                        path: '/install/mesh/gatewayList',
                        meta: {requireAuth: true, roles: ['I']},
                        component: () => import(/* webpackChunkName: "install" */ '@/views/install/meshOperating.vue'),
                        props: (route) => ({op: 'gatewayList'}),
                    },
                ]
            },
        ]
    }
]
