import Vue from 'vue'
import Vuex from 'vuex'
import NodeRSA from 'node-rsa'
import Axios from 'axios';

Vue.use(Vuex)

const tokenModule = {
    state: {token: '', status: ''},
    mutations: {
        set_token(state, tokenDetail) {
            state.token = tokenDetail.token;
            state.status = tokenDetail.status;
            sessionStorage.token = tokenDetail.token;
        },
        del_token(state) {
            state.token = '';
            state.status = '';
            sessionStorage.removeItem('token');
            Axios.get('/auth/logout')
        }
    },

};

const requestCancelModule = {
    state: {cancelTokenArr: []},
    mutations: {
        PUSH_CANCEL(state, payload) {
            state.cancelTokenArr.push(payload.cancelToken);
        },
        CLAER_CANCEL(state) {
            state.cancelTokenArr.forEach(item => {
                // item('路由跳转取消请求');
            });
            state.cancelTokenArr = [];
        }
    }
};

const keysModule = {
    state: {publicKey: '', privateKey: '', serverKey: ''},
    mutations: {
        set_key(state, pkcs) {
            let pkcsType = pkcs.pkcsType ? pkcs.pkcsType : 'pkcs8';//不为空则 设置为传入参数，为空则 设置为 pkcs8
            let pkcsSize = pkcs.pkcsSize || 1024;
            //1.创建RSA对象，并指定 秘钥长度
            var key = new NodeRSA({b: pkcsSize});
            key.setOptions({encryptionScheme: 'pkcs1'});//指定加密格式
            //2.生成 公钥私钥，使用 pkcs8标准，pem格式
            let publicDer = key.exportKey(pkcsType + '-public-pem');//制定输出格式
            publicDer = publicDer.replace(/\n/g, '');
            publicDer = publicDer.replace('-----BEGIN PUBLIC KEY-----', '');
            publicDer = publicDer.replace('-----END PUBLIC KEY-----', '');
            state.publicKey = publicDer;
            state.privateKey = key.exportKey(pkcsType + '-private-pem');
        },
        set_serverKey(state, serverKey) {
            state.serverKey = serverKey;
            sessionStorage.serverKey = serverKey;
        },
        del_key(state) {
            state.publicKey = '';
            state.privateKey = '';
            state.serverKey = '';
        }
    },
    actions: {
        getServerKey({commit, state}) {
            return new Promise((resolve, reject) => {
                Axios.get('/auth/safety/getPublicKey').then(response => {
                    commit('set_serverKey', response.data.data);
                    resolve(response.data.data);
                });
            })
        },

    }
};

const activeRouteModule = {
    state: {activeRoute: ''},
    mutations: {
        set_activeRoute(state, activeRoute) {
            state.activeRoute = activeRoute;
        },
    },
}

const themeColor = {
    state: {
        color: '#6AAF20',
        currentColor: '#FF7F50',
        voltageColor: '#63A6C5',
        tempColor: '#DAA520',
        avgColor: '#63A6C5',
        maxTempColor: '#F18565',
        minTempColor: '#64AFAC',
        menuColor: "#6AAF20",
        titleColor: "#228B22",
        tableColor: "#DAE8CA",
        fontColor: "#2C3E50",
        inputBgColor: 'rgb(255,255,252)',
        bgColor: 'rgb(242,242,242)',
        bgColor1: 'rgb(106,175,32, 0.3)',
        bgColor2: 'rgb(106,175,32, 0.6)',
    },
    mutations: {
        setThemeColer(state) {
            let theme = localStorage.getItem("theme");
            if (theme) {
                let item = JSON.parse(theme);
                state.color = item.color;
                state.currentColor = item.currentColor;
                state.voltageColor = item.voltageColor;
                state.tempColor = item.tempColor;
                state.avgColor = item.avgColor;
                state.maxTempColor = item.maxTempColor;
                state.minTempColor = item.minTempColor;
                state.menuColor = item.menuColor;
                state.titleColor = item.titleColor;
                state.tableColor = item.tableColor;
                state.fontColor = item.fontColor;
                state.inputBgColor = item.inputBgColor;
                state.bgColor = item.bgColor;
                state.bgColor1 = item.bgColor1;
                state.bgColor2 = item.bgColor2;
            }
        },
    },
    actions: {
        setThemeColer: function (context) {
            context.commit('setThemeColer');
        }
    }
};

export default new Vuex.Store({
    modules: {
        token: tokenModule,
        requestCancel: requestCancelModule,
        keys: keysModule,
        activeRoute: activeRouteModule,
        themeColor: themeColor
    }
});


